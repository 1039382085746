import { gql } from 'apollo-boost';

export const GET_CUSTOMER_SALESFORCE = gql`
  query contacts($firstName: String, $lastName: String, $email: String, $phone: String) {
    contacts(firstName: $firstName, lastName: $lastName, email: $email, phone: $phone) {
      Id
      FirstName
      LastName
      Email
      Phone
    }
  }
`;

export const GET_CUSTOMER_FRANPOS = gql`
	query contactsFranpos($firstName: String, $lastName: String, $email: String, $phone: String) {
		contactsFranpos(firstName: $firstName, lastName: $lastName, email: $email, phone: $phone) {
			Id
			FirstName
			LastName
			Email
			CellPhone
			LocationId
		}
	}
`

export const LINK_CUSTOMER = gql`
  mutation linkCustomer($salesforceId: String, $franposId: String, $franposLocationId: String) {
    linkCustomer(salesforceId: $salesforceId, franposId: $franposId, franposLocationId: $franposLocationId) {
		salesforceId
		franposId
		status
    }
  }
`;

export const GET_LINK = gql`
	query contactsLink($salesforceId: String) {
		contactsLink(salesforceId: $salesforceId) {
			salesforceId
			franposId
			franposFirstName
			franposLastName
			franposEmail
			franposCellPhone
			franposLocationId
		}
	}
`
