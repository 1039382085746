import React, { useState, useEffect } from 'react';
import moment from 'moment';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import {Container} from '@material-ui/core';

import { Link } from 'react-router-dom';
import endpoint from '../../config/endpoint.config';
import {useAuth, useUser} from '../../hooks';
import mudbayIcon from '../../assets//img/mudbayIcon.svg';
import { useStyles } from './styles';


export const Header = () => {
    const { isAuthenticated } = useAuth();
    const { user: { userInfo }, isLoading: isLoadingUser } = useUser();
    const classes = useStyles();
    const [time, setTime] = useState(moment().format('h:mm A'));


    const onLogout = () => {
        window.location.href = endpoint.COGNITO_LOGOUT;
        localStorage.clear();
    }

    const onLogin = () => {
        window.location.href = endpoint.COGNITO_LOGIN;
    }

    useEffect(() => {
        const interval = setInterval(() => {
            setTime(moment().format('h:mm A'));
        }, 60000);

        return () => clearInterval(interval);
    }, []);

    return (
        <Container maxWidth='lg'>
            <AppBar className={classes.header}>
                <Container maxWidth='lg'>
                    <Grid container justifyContent="flex-end" spacing={2}>
                        <Grid item>
                            {(isAuthenticated && !isLoadingUser && userInfo) ? <span className={classes.button}>Welcome {`${userInfo.givenName} ${userInfo.familyName}`}</span>
                                : <span className={classes.button}>Loading...</span>
                            }
                        </Grid>
						{/* <Grid item>
							<Button href={`/link`} className={classes.button}>Link Customer</Button>
						</Grid> */}
                        <Grid item>
                            {isAuthenticated ?
                                <Button onClick={onLogout} href={endpoint.COGNITO_LOGOUT} className={classes.button}>Logout</Button>
                                :
                                <Button onClick={onLogin} className={classes.button}>Cognito Login</Button>
                            }
                        </Grid>
                    </Grid>
                </Container>

            </AppBar>
            <div className={classes.storeHeader}>
                <Container maxWidth='lg'>
                    <Grid container direction="row" justifyContent="space-between" alignItems="center">
                        <Grid item xs={6} sm={8} className={classes.imageContainer}>
                            <Grid container direction="column">
                                <Grid item>
                                    <Link to={`/`}><span><img src={mudbayIcon} alt="mudbay-icon" /></span></Link>
                                </Grid>
                                {
                                    (userInfo && userInfo.storeName) && (
                                        <Grid item className={classes.storeHeading}>
                                            Store Ops Portal {userInfo.storeName}
                                        </Grid>
                                    )
                                }
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container direction="column" alignItems="flex-end">
                                <Grid item className={classes.storeHeading}>
                                    {moment().format('MMMM D, YYYY')}
                                </Grid>
                                <Grid item className={classes.storeHeading}>
                                    {time}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </Container>
    )
};
